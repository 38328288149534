import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
// import router from '@/router'

export default function useActionsAudit(root) {
  // Use toast
  const toast = useToast()

  const refActionsTable = ref(null)
  const currentRouteName = root.$router.currentRoute.name

  // Table Handlers
  const tableColumns = [
    { key: 'show_details', label: '#' },
    { key: 'school_name', label: 'Instance name' },
    { key: 'user_type', label: 'User Type' },
    { key: 'user_id', label: 'User Id' },
    { key: 'user_name', label: 'User Name' },
    { key: 'event', label: 'Event' },
    { key: 'model', label: 'Model' },
    { key: 'model_id', label: 'Model ID' },
    { key: 'created_at', label: 'Created At' },
  ]

  const defaultPerPage = Number(localStorage.getItem('actionsAuditPerPage'))
  const perPage = ref(defaultPerPage || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const filterInstance = ref('')
  const filterEvent = ref('')
  const filterModel = ref('')
  const filterDate = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refActionsTable.value ? refActionsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const clearFilters = () => {
    filterInstance.value = ''
    filterEvent.value = ''
    filterModel.value = ''
    filterDate.value = ''
  }

  const refetchData = () => {
    refActionsTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterInstance, filterEvent, filterModel, filterDate],
    ([currentPageVal, perPageVal, searchQueryVal, filterInstanceVal, filterEventVal, filterModelVal, filterDateVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterInstancePrevVal, filterEventPrevVal, filterModelPrevVal, filterDatePrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, currentRouteName, 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterInstanceVal !== filterInstancePrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'filterInstance', filterInstanceVal)
      }
      if (filterEventVal !== filterEventPrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'filterEvent', filterEventVal)
      }
      if (filterModelVal !== filterModelPrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'filterModel', filterModelVal)
      }
      if (filterDateVal !== filterDatePrevVal) {
        pushStateFiltersParams(root, currentRouteName, 'filterDate', filterDateVal)
      }
      refetchData()
    })

  const fetchActionsAudit = (ctx, callback) => {
    const queryParams = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }

    if (filterInstance.value) {
      queryParams.instance_id = filterInstance.value
    }
    if (filterEvent.value) {
      queryParams.event = filterEvent.value
    }
    if (filterModel.value) {
      queryParams.model = filterModel.value
    }
    if (filterDate.value) {
      queryParams.created_at = filterDate.value
    }
    if (searchQuery.value) {
      queryParams.model_id = searchQuery.value
    }
    store
      .dispatch('app-actions-audit/fetchActionsAudit', queryParams)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta
        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Actions Audit',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchInstancesList = queryParams => axios
    .get('/auth/schools', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Instances list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const fetchEventsList = queryParams => axios
    .get('/auth/audit-event-types', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Events list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const fetchModelsList = queryParams => axios
    .get('/auth/audit-models', { params: queryParams })
    .then(response => response.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Models list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  return {
    fetchActionsAudit,
    refActionsTable,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterInstance,
    filterEvent,
    filterModel,
    filterDate,
    sortBy,
    totalItems,
    isSortDirDesc,
    refetchData,
    clearFilters,
    fetchInstancesList,
    fetchEventsList,
    fetchModelsList,
  }
}
