<template>

  <div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <v-select
            v-model="filterInstance"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterInstanceOptions"
            :clearable="false"
            label="name"
            :reduce="option => option.id"
            :placeholder="'Select Instance'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <v-select
            v-model="filterEvent"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterEventOptions"
            :clearable="false"
            :placeholder="'Select Event'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <v-select
            v-model="filterModel"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterModelOptions"
            :clearable="false"
            :placeholder="'Select Model'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <flat-pickr
            v-model="filterDate"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', mode: 'range'}"
            :placeholder="'Select Date/Time'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search by Model ID"
                class="w-25"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refActionsTable"
        :items="fetchActionsAudit"
        :fields="tableColumns"
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: show_details -->
        <template #cell(show_details)="data">
          <feather-icon
            :icon="data.item._showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="16"
            class="align-middle text-body"
            @click="data.toggleDetails"
          />
        </template>

        <template #row-details="row">
          <b-row>
            <b-col
              cols="12"
            >
              <vue-json-compare
                :old-data="JSON.parse(row.item.old_values)"
                :new-data="SON.parse(row.item.new_values)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted } from '@vue/composition-api'

import useActionsAudit from '@/views/admin/actions-audit/useActionsAudit'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import store from '@/store'
import storeModule from '@/views/admin/actions-audit/actionsAuditStoreModule'
import vueJsonCompare from 'vue-json-compare'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BButton,

    flatPickr,
    vSelect,

    TableSpinner,
    vueJsonCompare,
  },
  filters: {
    shortDate(value) {
      return value ? moment(value).format('YYYY-MM-DD') : ''
    },
    longDate(value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:SS') : ''
    },
    shortId(id) {
      return id ? id.slice(-4) : ''
    },
  },
  data() {
    return {
      filterInstanceOptions: [],
      filterEventOptions: [],
      filterModelOptions: [],
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-actions-audit'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const {
      fetchActionsAudit,
      refActionsTable,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      filterInstance,
      filterEvent,
      filterModel,
      filterDate,
      sortBy,
      totalItems,
      isSortDirDesc,
      refetchData,
      clearFilters,
      fetchInstancesList,
      fetchEventsList,
      fetchModelsList,
    } = useActionsAudit(root)

    return {
      fetchActionsAudit,
      refActionsTable,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      filterInstance,
      filterEvent,
      filterModel,
      filterDate,
      sortBy,
      totalItems,
      isSortDirDesc,
      refetchData,
      clearFilters,
      fetchInstancesList,
      fetchEventsList,
      fetchModelsList,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  async created() {
    await this.setInstancesList()
    await this.setEventsList()
    await this.setModelsList()
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('actionsAuditPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = Number(localStorage.getItem('actionsAuditPerPage'))
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = Number(query.perPage) || defaultPerPage || 10
      this.searchQuery = query.searchQuery || ''
      this.filterInstance = Number(query.filterInstance) || ''
      this.filterEvent = query.filterEvent || ''
      this.filterModel = query.filterModel || ''
      this.filterDate = query.filterDate || ''
    },
    async setInstancesList() {
      this.filterInstanceOptions = await this.fetchInstancesList()
    },
    async setEventsList() {
      this.filterEventOptions = await this.fetchEventsList()
    },
    async setModelsList() {
      this.filterModelOptions = await this.fetchModelsList()
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .per-page-selector {
    width: 90px;
  }

</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
